<template>
  <div>
    <ItemHeader
      title="Preparação do paciente"
      :noBorder="true"
      collapseButton="button"
    >
      <template v-slot:content>
        <PatientPreparation :surgeryInformationId="surgeryInformationId" />
      </template>
    </ItemHeader>

    <ItemHeader
      title="Ficha de admissão"
      :noBorder="true"
      collapseButton="button"
    >
      <template v-slot:content>
        <AdmissionForm :surgeryInformationId="surgeryInformationId" />
      </template>
    </ItemHeader>

    <ItemHeader
      title="Informações do paciente"
      :noBorder="true"
      collapseButton="button"
    >
      <template v-slot:content>
        <PatientInformation :cols="12" :patientId="patientId" />
      </template>
    </ItemHeader>

    <ItemHeader title="Fichas gerais" :noBorder="true" collapseButton="button">
      <template v-slot:content>
        <GeneralSheets
          :surgeryInformationId="surgeryInformationId"
          :patientId="patientId"
          @generalSheetsUpdated="$emit('generalSheetsUpdated')"
        />
      </template>
    </ItemHeader>

    <ItemHeader
      id="history-container"
      title="Histórico da consulta cirúrgica"
      :noBorder="true"
      class="history history-container history-scroll"
      collapseButton="button"
    >
      <template v-slot:content>
        <AttendanceHistory 
          :patientId="patientId" 
          :types="attendanceTypes"
          hideBurger
        />
      </template>
    </ItemHeader>

    <ItemHeader title="Comentários" :noBorder="true" collapseButton="button">
      <template v-slot:content>
        <CommentList
          :surgeryInformationId="surgeryInformationId"
          :patientId="patientId"
        />
      </template>
    </ItemHeader>
  </div>
</template>

<script>
export default {
  name: 'AdditionalInfoContainer',
  props: {
    surgeryInformationId: String,
    patientId: String
  },
  data: () => ({
    attendanceTypes: [
        'Consulta cirúrgica', 
        'Evolução do anestesista',
        'Evolução da equipe multidisciplinar',
        'Anotações da equipe multidisciplinar'
      ],
  }),
  components: {
    ItemHeader: () =>
      import('@/components/SurgeryCenter/SurgeryInformation/ItemHeader'),
    PatientInformation: () =>
      import('@/layouts/SurgeryCenter/SurgeryInformation/PatientInformation'),
    CommentList: () => import('@/components/SurgeryCenter/General/CommentList'),
    PatientPreparation: () => import('./PatientPreparation'),
    AdmissionForm: () => import('./AdmissionForm'),
    AttendanceHistory: () => import('@/layouts/AttendanceHistory'),
    GeneralSheets: () => import('./GeneralSheets')
  }
}
</script>

<style lang="scss" scoped>
.history-scroll {
  max-height: 100vh;
  overflow-y: auto
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--neutral-000);
  border-radius: 0 0 8px 0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--neutral-300);
  border-radius: 100px;
  border: 3px solid var(--neutral-000);
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: var(--neutral-200);
}
</style>
